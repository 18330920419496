.rubricHead {
  cursor: pointer;
  color: #7F8FA4;
}
.rubricHead:hover {
  color: #000000;
}

.assignmentList {
  margin-left: 2em;
}

.assignmentRow {
  padding: .5em;
}
.assignmentRow:nth-child(odd) {
  background-color: #F3F3F3;
}
.assignmentRow p {
  margin-bottom: 0;
}

.partialMatch {
  color: red;
}

.bold {
  font-weight: 500;
}

.collapseChevron {
  marginLeft: 16px;
}

.disabled {
  opacity: 60%;
}
