.NotFoundUsers--container {
  max-width: 100%;
  max-height: 200px;
  margin-left: 20px;
}

.NotFoundUsers--item {
  margin-bottom: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.RubricHead {
  cursor: pointer;
  color: #7F8FA4;
}
.RubricHead:hover {
  color: #000000;
}
