
/* override bootstrap "strong" with Rubik "Regular" */
.TablePage__Table__Cell th {
  font-weight: 500 !important;
}

.PanelLabel_Box {
  display: flex;
  align-items: center;
  justify-content: space-between;

  border: 1px solid #E6EAEE;
  border-radius: 4px 4px 0 0;
  background: linear-gradient(0deg, #F4F7FA 0%, #FFFFFF 100%);
}

.PanelLabel_Clickable {
  cursor: pointer;
}

.PanelLabel {
  padding: 16px 25px 16px 16px;
  color: rgba(49,49,49,0.8);
  font-size: 16px;
  line-height: 24px;
}

.PanelLabel_Legend {
}
@media screen and (max-width: 496px) {
  .PanelLabel_Legend {
    display: none;
  }
}

.PanelLabel_Legend_dot {
  display:inline-block;
  border-radius: 50% 50% 50% 0;
  border: 3px solid #fff;
  width: 12px;
  height: 12px;
  transform: rotate(-45deg);
  background-color: white;
  margin-right:5px;
}

.PanelLabel_Legend_dot--blue {
  border-color: rgba(0, 156, 255);
}
.PanelLabel_Legend_dot--red {
  border-color: #F90000;
}
.PanelLabel_Legend_dot--yellow {
  border-color: #FFCA00;
}
.PanelLabel_Legend_dot--green {
  border-color: #0AAC9A;
}
.PanelLabel_Legend_period--blue {
  color:rgba(0, 156, 255);
}
.PanelLabel_Legend_period--red {
  color: #F90000;
}
.PanelLabel_Legend_period--yellow {
  color: #FFCA00;
}
.PanelLabel_Legend_period--green {
  color: #0AAC9A;
}


.PanelLabel_Chevron {
  margin-left: 16px;
  margin-right: 25px;
}

.PanelLabel__SiteCounts {
  display:flex;
  flex-direction: column;
  padding: 12px 16px 4px 16px;
  color: rgba(49,49,49,0.8);
}

.PanelLabel__SiteCounts__TopContainer {
  display:flex;
  flex-direction: row;
  gap: 16px;
}

.PanelLabel--top {
  font-size: 16px;
  line-height: 16px;
}
.PanelLabel--bottom {
  font-size: 14px;
  line-height: 24px;
}
.PanelLabel--bottom a {
  color: rgba(49,49,49,0.8);
  cursor: pointer;
}

.Search_Icon {
  height: 19px;  
  width: 19px;
  margin-right: 25px;
}

.Manage_Icon {
  height: 20px;  
  width: 20px;
  margin-left: 24px;
}

.Filter_Icons {
  /* margin: auto 25px auto auto; */
  margin-right: 25px;
  display: flex;
  align-items: center;
}
.Filter_Icon {
  padding: 12px 5px 12px 24px;
}

.Go_Threatdetails_Button {
  opacity:0.8;
  cursor: pointer;
  text-align: center;
  margin: 16px auto 16px 0px;
  /*line-height: 40px;*/
  height: 40px;
  width: 177px;
  border: 1px solid #4758BE;
  border-radius: 7px;
  /*background-color: #FFFFFF;*/
  font-size: 14px;
  font-weight: 500;
  color: #4758BE;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Go_Threatdetails_Button:hover {
  opacity:1;
  background: linear-gradient(0deg, #5368E0 0%, #5B72F5 100%);
  color: #FFFFFF;
  text-decoration: none;
}

.Go_Back_Text_Button {
  opacity:0.6;
  cursor: pointer;
  text-align: center;
  margin: 16px auto 16px 0px;
  height: 40px;
  width: 124px;
  border: none;
  font-size: 16px;
  font-weight: 500;
  color: #364760;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.Go_Back_Text_Button:hover {
  opacity:1;
  color: #364760;
  text-decoration: none;
}

.Go_Back_Text_Button:focus {
  box-shadow: none;
}

.ThumbTack {
  transition: transform 0.2s ease-in-out;
}
.ThumbTack:hover {
  opacity: 0.8;
}
.ThumbTack--rotated {
  transform: rotate(90deg);
}

.ResetString {
  font-weight: 500;
  text-decoration: underline;
}
